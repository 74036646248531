<template>
    <template v-if="!displayForm('editOrderInvoice')">
        <div class="w-full border border-gray-200 dark:border-gray-700 rounded-lg flex items-center py-2 px-3">
            <div class="flex flex-wrap items-start w-full">
                <item-text title="Kredītrēķina nr." :text="item.uuid" />
                <item-text title="Kredītrēķina datums" :text="item.date" />
                <item-text title="Apstiprināts" :text="item.accepted ? 'Jā' : 'Nē'" />
            </div>

            <div class="flex">
                <Button icon="download" @click="downloadCreditNotePdf"/>
                <Button icon="open-in-new" @click="openCreditNote()" />
            </div>
        </div>
    </template>

</template>

<script>
import {mapGetters} from "vuex"
import ItemText from "@/components/Components/ItemText"
import EditOrderInvoice from "@/components/Orders/OrderInvoice/EditOrderInvoice"

export default {
    name: "ShowOrderCreditNote",
    props: ['item'],
    components: {
        ItemText,
    },
    computed: {
        ...mapGetters({
            formsForDisplay: 'formsForDisplay',
        }),
    },
    methods: {
        showForm(formName){
        this.$store.dispatch('addFormForDisplay', formName)
        },
        displayForm(formName) {
            return this.formsForDisplay.includes(formName);
        },
        downloadCreditNotePdf() {
            this.$store.dispatch('getCreditNotePdf',  this.item)
        },
        openCreditNote() {
            let routeData = this.$router.resolve({path: '/invoices/credit_notes/' + this.item.id});
            window.open(routeData.href, '_blank');
        }
    }
}
</script>

<style>

</style>